import React, { useState, useCallback } from "react";

import Router from "next/router";

import IconButton from "@material-ui/core/IconButton";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

import SearchModal from "../SearchModal";
import { WebviewEvents, sendWebViewEvent } from "../../../helpers/webview";
import { useSelector } from "react-redux";

const Breadcrumb = props => {
  const [searchModalActive, setSearchModalActive] = useState(false);
  const mobileAppVersion = useSelector(({ common }) => common.mobileAppVersion);

  const toggleSearchModalActive = useCallback(() => {
    if (mobileAppVersion >= 3) {
      sendWebViewEvent(WebviewEvents.Push, ["Search", {}]);
      return;
    } else {
      setSearchModalActive(searchModalActive => !searchModalActive);
    }
  }, []);
  const handleGoBack = useCallback(() => {
    return Router.back();
  }, []);
  return (
    <>
      <SearchModal
        active={searchModalActive}
        onClose={toggleSearchModalActive}
      />
      <div className="page-breadcrumb mx-auto max-w-screen-md">
        <span className="page-breadcrumb__back" onClick={handleGoBack}></span>
        {props.title && (
          <h1 className="page-breadcrumb__title truncate">{props.title}</h1>
        )}
        <div className="ml-auto mr-3">
          <IconButton onClick={() => Router.push("/")}>
            <HomeOutlinedIcon />
          </IconButton>
          <IconButton onClick={toggleSearchModalActive}>
            <SearchOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => Router.push("/order/cart.html")}>
            <ShoppingCartOutlinedIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};
export default React.memo(Breadcrumb);
